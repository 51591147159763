/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable jsx-a11y/anchor-is-valid */

'use client'

import { trackEvent } from '@acko-tech/building-blocks.ui.acko-analytics'
import React from 'react'

interface ClientAnchorTagProps {
  href: string
  children: React.ReactNode | string
  segmentEventProps?: object
  segmentEventName?: string
  styles?: string
}
const ClientAnchorTag = ({
  href,
  children,
  styles,
  segmentEventName,
  segmentEventProps = {},
}: ClientAnchorTagProps) => {
  const handleOnClick = async e => {
    e.preventDefault()
    if (segmentEventName) {
      const updatedSegmentEventProps = segmentEventProps

      await trackEvent(segmentEventName, {
        ...updatedSegmentEventProps,
        source: document.referrer,
        url: window?.location?.href,
      }).finally(() => {
        window.location.href = href
      })
    }
  }
  return (
    <a onClick={handleOnClick} className={styles}>
      {children}
    </a>
  )
}
export default ClientAnchorTag
