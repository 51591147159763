'use client'

import { useState } from 'react'
import { toggleHeaderHamburgerClass } from '@utils/brandUtilsClientSide'
import styles from './brandheader.module.scss'

const HamBurger = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleOnClick = () => {
    setIsOpen(prev => toggleHeaderHamburgerClass(styles, prev))
  }

  return (
    <div className={styles.hamBurgerContainer} onClick={handleOnClick}>
      <div className={`${styles.hamBurger} ${isOpen ? styles.active : ''}`} />
    </div>
  )
}

export default HamBurger
