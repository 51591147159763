export const toggleHeaderHamburgerClass = (styles, prev) => {
  const list = document.getElementById('brand-header')
  if (prev) list?.classList.remove(styles.activeList)
  else list?.classList.add(styles.activeList)
  return !prev
}

// TODO: Function to be removed after video api gets fixed
export const timeAgo = dateString => {
  const diffInSeconds = Math.floor((new Date().getTime() - new Date(dateString).getTime()) / 1000)
  const intervals: Array<[string, number]> = [
    ['year', 31536000],
    ['month', 2592000],
    ['week', 604800],
    ['day', 86400],
    ['hour', 3600],
    ['minute', 60],
    ['second', 1],
  ]

  for (let i = 0; i < intervals.length; i++) {
    const [label, seconds] = intervals[i]
    const count = Math.floor(diffInSeconds / seconds)
    if (count >= 1) return `${count} ${label}${count > 1 ? 's' : ''} ago`
  }

  return 'just now'
}

export const getVideoShareUrl = videoId =>
  typeof window !== 'undefined' ? `${window?.location?.origin}/videos/${videoId}` : ''
